const hl = {
  name: 'Hallandstrafiken',
  options: {},
  theme: {
    primary: { main: '#006FB7', light: '#cce2f0' },
    secondary: { main: '#00B0E0' },
    error: { main: '#CF374C', contrastText: '#ffffff' },
    purple: { main: '#625396', contrastText: '#ffffff' },
    ocean: { main: '#367c88', contrastText: '#ffffff' },
    green: { main: '#128758', contrastText: '#ffffff' },
  },
};

export default hl;
