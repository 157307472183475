import http, { httpPrimary } from './httpService';

// POST
// ​/user​/logout
export const userLogout = async () => {
  const res = await httpPrimary.post(`/user/logout`);
  return res.data;
};

// GET
// ​/user​
export const userGet = async () => {
  const res = await http.get(`/user`);
  return res.data;
};

// PUT
// ​/user​/update
export const userUpdate = async (user) => {
  const res = await http.put('/user/customer/update', user);
  return res.data;
};

export const userDelete = async () => {
  const res = await http.delete('/user/delete');
  return res.data;
};

// PUT
// ​/user​/password​/update
export const userPasswordUpdate = async (request) => {
  const res = await http.put(`/user/password/update`, request);
  return res.data;
};

// POST
// ​/user​/favoriteaddress​/create
export const userFavoriteAddressCreate = async (request) => {
  const res = await http.post('/user/favoriteaddress/create', request);
  return res.data;
};

// PUT
// ​/user​/favoriteaddress​/update

// POST
// ​/user​/favoriteaddress​/delete
export const userFavoriteAddressDelete = async (id) => {
  const res = await http.post('/user/favoriteaddress/delete', { id });
  return res.data;
};

// POST
// ​/user​/favoriteaddresses​/list
export const userFavoriteAddressesList = async () => {
  const res = await http.post(`/user/favoriteaddresses/list`);
  return res.data.data;
};

// POST
// ​/user​/favoritetrip​/create
export const userFavoriteTripCreate = async (request) => {
  const res = await http.post(`/user/favoritetrip/create`, request);
  return res.data;
};

// PUT
// ​/user​/favoritetrip​/update
export const userFavoriteTripUpdate = async (request) => {
  const res = await http.put('/user/favoritetrip/update', request);
  return res.data;
};

// POST
// ​/user​/favoritetrip​/delete
export const userFavoriteTripDelete = async (id) => {
  const res = await http.post('/user/favoritetrip/delete', { id: parseInt(id) });
  return res.data;
};

// POST
// ​/user/favoritetrips/list
export const userFavoriteTripsList = async () => {
  const res = await http.post(`/user/favoritetrips/list`);
  return res.data.data.favoriteTrips;
};

// POST
// ​/user​/permits​/list
export const userPermitsList = async () => {
  const hasKey = localStorage.getItem('has-key') === 'true';
  if (!hasKey) return null;

  const permits = 'travelTypeID,extraAid,carryingService,stairClimber,maxCompanions,allowedPlacing,sharedRide,validFrom,validUntil,invalidFrom,invalidUntil,tripsGranted,tripCounterConsumed,customerGroup';
  const res = await http.post(`/user/permits/list`, { permits });
  return res?.data?.data?.permits;
};

// GET
// ​/user​/information​
export const userInformationList = async () => {
  const res = await http.get('/user/information');
  return res.data.data;
};

// POST
// ​/user​/addresses​/customer
export const userAddressesCustomer = async (customerGroup) => {
  const res = await http.post(`/user/addresses/customer`, { customerGroup });
  return res.data.data;
};
