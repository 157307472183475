import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { themes } from '../../themes/themes';

import Mui from '../../components/material';
import Icon from '../../components/icons';

import FooterLinkList from '../../components/FooterLinkListItem';
import Flowers from '../../images/footer-flowers.svg';

import Appstore from '../../images/downloadInAppstore.svg';
import GooglePlay from '../../images/downloadInGooglePlay.svg';

import smf from '../../themes/smf/smf_white.svg';
import hl from '../../themes/hl/hl_white.svg';
import ol from '../../themes/ol/ol_white.svg';
import vt from '../../themes/vt/vt_white.svg';

const logos = {
  smf: smf,
  hl: hl,
  ol: ol,
  vt: vt,
};

const useStyles = makeStyles((theme) => ({
  banner: {
    padding: theme.spacing(4, 2),
    backgroundColor: theme.palette.common.white,
    borderBottom: `3px solid ${theme.palette.primary.main}`,

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  bannerWrapper: {
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  bannerPhoneNumber: {
    padding: theme.spacing(0, 0),
    width: '50%',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 6),
    },
  },
  bannerPhoneNumberText: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 1.6,
    whiteSpace: 'nowrap',

    '& p': {
      color: theme.palette.primary.main,
      margin: 0,
    },
    '& strong': {
      color: theme.palette.common.black,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  footer: {
    backgroundColor: '#49545f',
  },
  heading: {
    margin: 0,
  },
  accordionRoot: {
    color: theme.palette.common.white,
    backgroundColor: '#49545f',
    margin: theme.spacing(0, 0),
  },
  accordionIcon: {
    color: theme.palette.common.white,
  },
  summaryRoot: {
    '&:focus, &:active': {
      backgroundColor: 'transparent',
      outline: `3px solid ${theme.palette.common.white}`,
      outlineOffset: -3,
    },

    [theme.breakpoints.up('sm')]: {
      pointerEvents: 'none',
    },
  },
  summaryExpanded: {
    borderBottom: '0 !important',
  },
  detailsRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  downloadOurApps: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  downloadAppsList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  downloadAppsLink: {
    display: 'flex',
    marginRight: 10,
    marginBottom: 10,

    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.common.white}`,
    },
  },
  bottom: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2f3842',
    minHeight: 82,
    borderTop: '1px solid #ffffff',
  },
  version: {
    position: 'absolute',
    color: '#7F878F',
    bottom: 5,
    left: 5,
  },
  logo: {
    maxHeight: 34,
  },
}));

const FooterContainer = () => {
  const classes = useStyles();

  const {
      sitemap,
      contact,
      help,
      messages,
      links,
  } = useSelector((state) => state.cms);

  const getFlexService = () => {
    if (messages) {
      const content = messages.find((item) => item.name === 'Boka Flexlinjeresa');
      if (content) return content.content;
      return '';
    }
  };

  const getTransportService = () => {
    if (messages) {
      const content = messages.find((item) => item.name === 'Boka färdtjänst');
      if (content) return content.content;
      return '';
    }
  };

  const appStoreLink = () => {
    if (links) {
      const content = links.find((item) => item.name === 'AppStore');
      if (content) return content.url;
      return '';
    }
  };

  const googlePlayLink = () => {
    if (links) {
      const content = links.find((item) => item.name === 'GooglePlay');
      if (content) return content.url;
      return '';
    }
  };

  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  return (
    <footer>
      <Mui.Box className={classes.banner}>
        <Mui.Container maxWidth='md'>
          <Mui.Box display='flex' justifyContent='space-between' alignItems='center'>
            <Mui.Hidden smDown>
              <img src={Flowers} alt='' />
            </Mui.Hidden>
            <Mui.Box display='flex' className={classes.bannerWrapper}>
              <Mui.Typography variant='h2' color='primary' tabIndex={0}>
                <FormattedMessage id='footer.contactUs.bookByPhone' />
              </Mui.Typography>
              <Mui.Box display='flex' my={3}>
                <Mui.Box className={classes.bannerPhoneNumber}>
                  <Mui.Box
                    tabIndex={0}
                    className={classes.bannerPhoneNumberText}
                    dangerouslySetInnerHTML={{ __html: getTransportService() }}></Mui.Box>
                </Mui.Box>
                <Mui.Box className={classes.bannerPhoneNumber}>
                  <Mui.Box
                    tabIndex={0}
                    className={classes.bannerPhoneNumberText}
                    dangerouslySetInnerHTML={{ __html: getFlexService() }}></Mui.Box>
                </Mui.Box>
              </Mui.Box>
            </Mui.Box>
          </Mui.Box>
        </Mui.Container>
      </Mui.Box>
      <Mui.Box className={classes.footer}>
        <Mui.Container maxWidth='lg'>
          <Mui.Grid container>
            <Mui.Grid item xs={12} sm={4}>
              <Mui.Accordion defaultExpanded={!isMobileOnly} square elevation={0} classes={{ root: classes.accordionRoot }}>
                <Mui.AccordionSummary
                  tabIndex={isMobile ? 0 : -1}
                  classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded }}
                  expandIcon={isMobileOnly ? <Icon.ExpandMoreIcon className={classes.accordionIcon} /> : <></>}
                  aria-controls='contactUs-content'
                  id='contactUs-header'>
                  <Mui.Typography variant='h3' className={classes.heading}>
                    <FormattedMessage id='footer.contactUs.heading' />
                  </Mui.Typography>
                </Mui.AccordionSummary>
                <Mui.AccordionDetails classes={{ root: classes.detailsRoot }}>
                  <FooterLinkList links={contact} />
                </Mui.AccordionDetails>
              </Mui.Accordion>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={4}>
              <Mui.Accordion defaultExpanded={!isMobileOnly} square elevation={0} classes={{ root: classes.accordionRoot }}>
                <Mui.AccordionSummary
                  tabIndex={isMobile ? 0 : -1}
                  classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded }}
                  expandIcon={isMobileOnly ? <Icon.ExpandMoreIcon className={classes.accordionIcon} /> : <></>}
                  aria-controls='website-content'
                  id='website-header'>
                  <Mui.Typography variant='h3' className={classes.heading}>
                    <FormattedMessage id='footer.website.heading' />
                  </Mui.Typography>
                </Mui.AccordionSummary>
                <Mui.AccordionDetails classes={{ root: classes.detailsRoot }}>
                  <FooterLinkList links={sitemap} />
                </Mui.AccordionDetails>
              </Mui.Accordion>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={4}>
              <Mui.Accordion defaultExpanded={!isMobileOnly} square elevation={0} classes={{ root: classes.accordionRoot }}>
                <Mui.AccordionSummary
                  tabIndex={isMobile ? 0 : -1}
                  classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded }}
                  expandIcon={isMobileOnly ? <Icon.ExpandMoreIcon className={classes.accordionIcon} /> : <></>}
                  aria-controls='helpWithService-content'
                  id='helpWithService-header'>
                  <Mui.Typography variant='h3' className={classes.heading}>
                    <FormattedMessage id='footer.helpWithService.heading' />
                  </Mui.Typography>
                </Mui.AccordionSummary>
                <Mui.AccordionDetails classes={{ root: classes.detailsRoot }}>
                  <FooterLinkList links={help} />
                </Mui.AccordionDetails>
              </Mui.Accordion>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12}>
              {appStoreLink() || googlePlayLink() ?
                <Mui.Box mx={3}>
                  <Mui.Typography variant='h3' className={classes.downloadOurApps} tabIndex={0}>
                    <FormattedMessage id='footer.download.downloadOurApps' />
                  </Mui.Typography>
                  <ul className={classes.downloadAppsList}>
                    {appStoreLink() ?
                      <li>
                        <a
                          href={appStoreLink()}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={classes.downloadAppsLink}>
                          <img src={Appstore} alt='Hämta i App Store' />
                        </a>
                      </li>
                      : null }
                    {googlePlayLink() ? <li>
                      <a
                        href={googlePlayLink()}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.downloadAppsLink}>
                        <img src={GooglePlay} alt='Ladda ned på Google Play' />
                      </a>
                    </li> : null }
                  </ul>
                </Mui.Box> : null }
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Container>
      </Mui.Box>
      <Mui.Box className={classes.bottom}>
        <img
          src={logos[region]}
          alt={`${themes[region].name} logga`}
          className={classes.logo}
          tabIndex={0}
        />
      </Mui.Box>
    </footer>
  );
};

export default FooterContainer;
