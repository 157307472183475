import { createTheme } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';
import smf from '../themes/smf/smf_theme';
import hl from '../themes/hl/hl_theme';
import ol from './ol/ol_theme';
import vt from '../themes/vt/vt_theme';

export const themes = {
  smf: smf,
  hl: hl,
  ol: ol,
  vt: vt,
};

const region = JSON.parse(localStorage.getItem('env')).REGION || '';
const theme = createTheme({
  palette: {
    ...themes[region].theme,
    text: {
      primary: '#000000',
      secondary: '#666',
    },
    background: {
      default: '#f7f7f7',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 730,
      md: 986,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 5,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: ['Roboto'],
    h3: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: 0.48,
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          '&:focus, &:active': {
            outline: `3px solid ${themes[region].theme.primary.main}`,
            outlineOffset: 2,
          },
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#949494',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: '#0009',
      },
    },
    MuiDivider: {
      middle: {
        marginLeft: 20,
        marginRight: 20,
      },
    },
    MuiInputLabel: {
      asterisk: {
        display: 'none',
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#ffffff',

          '&:hover': {
            backgroundColor: '#ffffff',
          },
        },
      },
      button: {
        '&:focus, &:hover': {
          backgroundColor: '#ffffff',
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 80,
        fontSize: '1rem',
        textTransform: 'none',
        letterSpacing: 0.41,
        '&:focus, &:active': {
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
      },
      text: {
        '&:focus': {
          color: '#ffffff',
          backgroundColor: `${themes[region].theme.primary.main}`,
        },
        '&:hover': {
          color: '#ffffff',
          backgroundColor: `${themes[region].theme.primary.main}`,
        },
      },
      textPrimary: {
        '&:focus': {
          color: '#ffffff',
          backgroundColor: `${themes[region].theme.primary.main}`,
        },
        '&:hover': {
          color: '#ffffff',
          backgroundColor: `${themes[region].theme.primary.main}`,
        },
      },
      containedPrimary: {
        border: `1px solid ${themes[region].theme.primary.main}`,
        '&:disabled': {
          border: `1px solid rgba(0, 0, 0, 0.12)`,
        },
        '&:focus': {
          border: `1px solid ${themes[region].theme.primary.main}`,
          color: `${themes[region].theme.primary.main}`,
          backgroundColor: `${themes[region].theme.primary.light}`,
        },
        '&:hover': {
          border: `1px solid ${themes[region].theme.primary.main}`,
          color: `${themes[region].theme.primary.main}`,
          backgroundColor: `${themes[region].theme.primary.light}`,
        },
      },
      outlined: {
        backgroundColor: `#ffffff`,
      },
      outlinedPrimary: {
        '&:focus': {
          color: `${themes[region].theme.primary.main}`,
          backgroundColor: `${themes[region].theme.primary.light}`,
        },
        '&:hover': {
          color: `${themes[region].theme.primary.main}`,
          backgroundColor: `${themes[region].theme.primary.light}`,
        },
      },
      fullWidth: {
        display: 'block',
        maxWidth: 288,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: 0,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: '1rem',
        textTransform: 'none',
        letterSpacing: '2px',
        '&:focus, &:active': {
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
      },
    },
    MuiToggleButtonGroup: {
      groupedHorizontal: {
        '&:not(:first-child)': {
          borderLeft: `1px solid ${themes[region].theme.primary.main}`,
        },
      },
    },
    MuiToggleButton: {
      root: {
        fontWeight: 500,
        color: grey[700],
        border: '1px solid #949494',
        '&:hover': {
          backgroundColor: themes[region].theme.primary.light,
        },
        '&$selected': {
          color: themes[region].theme.primary.main,
          backgroundColor: themes[region].theme.primary.light,
          border: `1px solid ${themes[region].theme.primary.main}`,
          '&:hover': {
            backgroundColor: themes[region].theme.primary.light,
          },
        },
      },
    },
    MuiAccordion: {
      root: {
        margin: '20px 0',
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: '20px 0',
          '&:first-child': {
            marginTop: 20,
          },
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 48,
        padding: '0 16px',
        '&$expanded': {
          minHeight: 48,
          borderBottom: `1px solid ${grey[200]}`,
        },
        '&:focus, &:active': {
          backgroundColor: '#ffffff',
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
      },
      content: {
        margin: '12px 0',
        '&$expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: 'block',
        padding: 16,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '10px 20px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '10px 20px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '10px 20px',
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: '#ffffff',
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
        '&:focus, &:hover': {
          backgroundColor: '#ffffff',
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
        '&[aria-disabled="true"]': {
          opacity: 1,
        },
      },
    },
    MuiSwitch: {
      track: {
        opacity: 0.2,
      },
      colorPrimary: {
        color: '#727272',
        '&:focus, &:active': {
          outline: `3px solid ${themes[region].theme.primary.main}`,
          outlineOffset: -3,
        },
        '&$checked': {
          color: themes[region].theme.primary.main,
        },
        '&$checked + $track': {
          backgroundColor: themes[region].theme.primary.main,
          opacity: 0.2,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.5rem',
  fontWeight: 500,

  [theme.breakpoints.up('md')]: {
    fontSize: '2.125rem',
  },
};

theme.typography.h2 = {
  fontSize: '1.25rem',
  fontWeight: 500,

  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

theme.overrides.MuiAutocomplete.popper = {
  minWidth: 260,
  [theme.breakpoints.up('md')]: {
    minWidth: 300,
  },
};

export default theme;
