import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NewRedirect from './NewRedirect';

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const { isAuthenticated, primaryUser } = useSelector((state) => state.auth);
  // const { data, isFetching: isLoadingPermits } = usePermits();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (primaryUser) {
            return <Component {...rest} {...props} />;
          }
        } else {
          return <NewRedirect url={`${window.location.origin}/ny/login`} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
