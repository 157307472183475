import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CONSTANTS from '../store/constants';

import { tripsOrderGet, tripsNotifications } from '../repository/trips';
import { addressSearch } from '../repository/address';
import { setBookingAction } from '../store/booking/bookingActions';
import { dialogDisplayMessageAction } from '../store/dialog/dialogActions';

export default function useOrderDetail() {
  const dispatch = useDispatch();
  const enviroments = ['smf'];
  const { type, nodeId, mode } = useParams(); // nodeId is ether a favoriteTripId or orderId

  const { customer, homeAddress } = useSelector((state) => state.auth);
  const { settings } = useSelector((state) => state.system);

  const fetchAddressSearch = async (addressString) => {
    const request = {
      query: addressString,
      municipality: [...customer?.allowedMunicipalities],
      flex: type.toLowerCase() === 'fx' ? true : false,
      nearLocation: homeAddress.location,
    };
    const { addresses } = await addressSearch(request);
    const found = addresses.addresser.find((row) => row.address.toLowerCase() === addressString.toLowerCase());

    return !!found ? found : addresses.addresser[0];
  };

  return useMutation(
    async ({ swap, permit }) => {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderGet' });
      return await tripsOrderGet('tripsOrderGet', nodeId);
    },
    {
      onError: (error) => {
        console.log(error);
        dispatch(dialogDisplayMessageAction(error.code));
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderGet' });
      },
      onSuccess: async (order, { swap, permit }) => {
        let pickupAddressString = `${order.pickup.strName}${order.pickup.strNbr ? ` ${order.pickup.strNbr}` : ''}, ${order.pickup.city}`;
        let pickupLetter = order.pickup.strNbrLetter.toUpperCase();
        let dropoffAddressString = `${order.dropoff.strName}${order.dropoff.strNbr ? ` ${order.dropoff.strNbr}` : ''}, ${order.dropoff.city}`;
        let dropoffLetter = order.dropoff.strNbrLetter.toUpperCase();

        if (swap) {
          pickupAddressString = `${order.dropoff.strName}${order.dropoff.strNbr ? ` ${order.dropoff.strNbr}` : ''}, ${order.dropoff.city}`;
          dropoffAddressString = `${order.pickup.strName}${order.pickup.strNbr ? ` ${order.pickup.strNbr}` : ''}, ${order.pickup.city}`;
          pickupLetter = order.dropoff.strNbrLetter.toUpperCase();
          dropoffLetter = order.pickup.strNbrLetter.toUpperCase();
        }

        // Setup Booking State
        const pickupAddresses = await fetchAddressSearch(pickupAddressString);
        const dropoffAddresses = await fetchAddressSearch(dropoffAddressString);
        const { driverInstructions } = await tripsNotifications();

        let pickupHelpItems = driverInstructions.find((item) =>
          order.pickup.addrNote.Information.trim().includes(item.text)
        );
        if (!pickupHelpItems) pickupHelpItems = { id: 0 };

        let dropoffHelpItems = driverInstructions.find((item) =>
          order.dropoff.addrNote.Information.trim().includes(item.text)
        );
        if (!dropoffHelpItems) dropoffHelpItems = { id: 0 };

        let pickupHelp;
        let dropoffHelp;
        const region = JSON.parse(localStorage.getItem('env')).REGION || '';
        if (enviroments.includes(region) && permit.travelType.type.toLowerCase() !== 'fx') {
          let customerPhonenumber = customer?.mobPhoneNbr ? customer?.mobPhoneNbr : customer?.phoneNbr;
          const customerHomeAddress = `${customer?.strName}${customer?.strNbr ? ` ${customer?.strNbr}` : ''}${customer?.strNbrLetter}, ${customer?.postalDistrict}`;

          if (customerHomeAddress === pickupAddresses.address) {
            customerPhonenumber = customer?.phoneNbr ? customer?.phoneNbr : customer?.mobPhoneNbr;
          }

          if (swap) {
            pickupHelp = [{ ...dropoffHelpItems, contactPhonenumber: customerPhonenumber }];
            dropoffHelp = [{ ...pickupHelpItems, contactPhonenumber: '' }];
          } else {
            pickupHelp = [{ ...pickupHelpItems, contactPhonenumber: customerPhonenumber }];
            dropoffHelp = [{ ...dropoffHelpItems, contactPhonenumber: '' }];
          }
        } else {
          if (swap) {
            pickupHelp = [{ ...dropoffHelpItems, contactPhonenumber: order.pickup.addrNote.Kontaktnummer.trim() }];
            dropoffHelp = [{ ...pickupHelpItems, contactPhonenumber: order.dropoff.addrNote.Kontaktnummer.trim() }];
          } else {
            pickupHelp = [{ ...pickupHelpItems, contactPhonenumber: order.pickup.addrNote.Kontaktnummer.trim() }];
            dropoffHelp = [{ ...dropoffHelpItems, contactPhonenumber: order.dropoff.addrNote.Kontaktnummer.trim() }];
          }
        }

        let extraAids = order.travellers[0].extraAid.map((aid) => aid.id);

        const booking = {
          requestPickupTime: !order.latestRequestedDropoffTime
            ? moment(order.promisedTime)
                .add(mode === 'retur' ? 60 : 0, 'minutes')
                .format('YYYY-MM-DD HH:mm')
            : '',
          requestDropOffTime: order.latestRequestedDropoffTime
            ? moment(order.latestRequestedDropoffTime)
                .add(mode === 'retur' ? parseInt(settings.order_minimum_minutes_until_arrival.value) : 0, 'minutes')
                .format('YYYY-MM-DD HH:mm')
            : '',
          noteToCCandVehicle: '',
          nbrCompanions: order.travellers[0].nbrCompanions,
          nbrCoTravellers: order.travellers[0].nbrCoTravellers,
          pickupAddress: pickupAddresses,
          pickupLetter: pickupLetter,
          pickupHelp: pickupHelp,
          dropoffAddress: dropoffAddresses,
          dropoffLetter: dropoffLetter,
          dropoffHelp: dropoffHelp,
          extraAids: extraAids.length > 0 ? [...extraAids] : [permit.extraAidNoKey],
        };

        dispatch(setBookingAction(booking));
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderGet' });
      },
    }
  );
}
