import { useEffect } from "react";

const NewRedirect = ({ url }) => {
    useEffect(() => {
        if (url) {
            window.location.replace(url);
        }
    }, [url]);

    return <></>;
};

export default NewRedirect;
