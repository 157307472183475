import React, { useEffect, useState, useCallback, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import constants from '../../store/constants';

import {
  setDropoffAddressAction,
  setPickupAddressAction,
  setDropoffLetterAction,
  setPickupLetterAction,
  setRequestPickupTimeAction,
  setRequestDropoffTimeAction,
  bookingSwapAddressesAction,
  setPickupHelpAction,
} from '../../store/booking/bookingActions';
import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import { userFavoriteAddressesList, userAddressesCustomer } from '../../repository/user';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Mui from '../../components/material';
import Icon from '../../components/icons';

import SelectAddressField from '../../components/SelectAddressField';
import SelectAddressMap from '../../components/SelectAddressMap';

import { getFormattedDate } from '../../utils/validators';
import useOrderList from '../../hooks/useOrderList';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  swapWrapper: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 30,
    },
  },
  swapButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    '&:focus, &:active, &:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      zIndex: 500,
      right: -5,
      top: -2,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  addressFieldIcon: {
    marginRight: theme.spacing(2),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButtonGroup: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toggleButton: {
    minWidth: 128,
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: 0.48,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  card: {
    marginTop: '1em',
  },
  errorText: {
    color: '#c0392b',
  },
}));

const TripContainer = forwardRef(function TripContainer ({ validDates, dateHelperText, customerGroup, setValidationMessages }, ref) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { nodeId, mode, type } = useParams(); // nodeId is ether a favoriteTripId or orderId

  const booking = useSelector((state) => state.booking);
  const { settings } = useSelector((state) => state.system);
  const { customer } = useSelector((state) => state.auth);

  const minDate = getFormattedDate(validDates[0]);
  const maxDate = getFormattedDate(validDates[validDates.length - 1]);
  const validFormattedDates = validDates.map((date) => date.format('YYYY-MM-DD'));

  const { isFetching: isFetchingFavoriteAddresses } = useQuery('userFavoriteAddressesList', userFavoriteAddressesList, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: ({ favoriteAddresses }) => {
      setFavoriteAddresses(
        favoriteAddresses
          .filter((address) => {
            const addressTypes = ['hpla'];
            if (type.toLowerCase() === 'fx') return addressTypes.includes(address.addressType);
            return !addressTypes.includes(address.addressType);
          })
          .map((address) => {
            if (address.stopName) {
              return {
                address: `${address.stopName}`,
                letter: ``,
                location: null,
                type: address.addressType,
              };
            } else {
              return {
                address: `${address.strName}${address.strNbr ? ` ${address.strNbr}` : ''}, ${address.city}`,
                letter: `${address.strNbrLetter.toUpperCase()}`,
                location: null,
                type: address.addressType,
              };
            }
          })
      );
    },
  });

  const { isFetching: isFetchingCustomerAddresses } = useQuery(['userAddressesCustomer', customerGroup],
    () => userAddressesCustomer(customerGroup),
    {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: ({ customerAddresses }) => {
      setCustomerAddresses(
        customerAddresses.map((address) => {
          return {
            address: `${address.strName}${address.strNbr ? ` ${address.strNbr}` : ''}, ${address.city}`,
            letter: `${address.strNbrLetter.toUpperCase()}`,
            location: null,
            type: 'customer',
          };
        })
      );
    },
  });

  const [favoriteAddresses, setFavoriteAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const { data: orderList } = useOrderList();

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedHour, setSelectedTimeHours] = useState('');
  const [selectedMinute, setSelectedTimeMinutes] = useState('');
  const [dateMessage, setDateMessage] = useState('');
  const [timeType, setTimeType] = useState('pickup');
  const [minimumMinutesUntilArrival, setMinimumMinutesUntilArrival] = useState(0);

  const handlePickupAddress = useCallback(
    (address) => {
      const enviroments = ['smf'];
      const region = JSON.parse(localStorage.getItem('env')).REGION || '';
      if (enviroments.includes(region)) {
        if (address.type === 'home') {
          const contactPhonenumber = customer?.phoneNbr ? customer?.phoneNbr : customer?.mobPhoneNbr;
          dispatch(setPickupHelpAction({ ...booking?.pickupHelp[0], contactPhonenumber: contactPhonenumber }));
        } else {
          const contactPhonenumber = customer?.mobPhoneNbr ? customer?.mobPhoneNbr : customer?.phoneNbr;
          dispatch(setPickupHelpAction({ ...booking?.pickupHelp[0], contactPhonenumber: contactPhonenumber }));
        }
      }

      dispatch(setPickupAddressAction(address));
    },
    [booking, customer, dispatch]
  );

  const handleDropoffAddress = useCallback(
    (address) => {
      dispatch(setDropoffAddressAction(address));
    },
    [dispatch]
  );

  const handleSwapAddresses = () => {
    dispatch(bookingSwapAddressesAction());
  };

  const handlePickupLetter = ({ target: { value } }) => {
    dispatch(setPickupLetterAction(value.toUpperCase()));
  };
  const handleDropoffLetter = ({ target: { value } }) => {
    dispatch(setDropoffLetterAction(value.toUpperCase()));
  };

  const dispatchRequestTime = useCallback((type, date, time) => {
    const dateString = `${moment(date).format('YYYY-MM-DD')} ${time}`;
    if (type === 'pickup') {
      dispatch(setRequestPickupTimeAction(dateString));
    } else {
      dispatch(setRequestDropoffTimeAction(dateString));
    }
  }, [dispatch]);

  const handleDateChange = (oldValue, newValue) => {
    // Workaround for Moment-bug
    newValue = newValue.replace('maj', 'may').replace('oktober', 'october');

    const today = moment();
    const todaysYear = today.format('YYYY');
    const newDateYear = today.format('MM') === '12' && newValue.includes('januari') ? `${parseInt(todaysYear) + 1}` : todaysYear;
    const newDate = moment(moment(`${newValue} ${newDateYear}`).format('YYYY-MM-DD'));

    dispatchRequestTime(timeType, newDate, `${selectedHour}:${selectedMinute}`);
  };

  const handleDateKeys = (event) => {
    let newDate;
    let isValid = false;
    switch (event.key) {
      case 'ArrowUp':
        newDate = moment(selectedDate).add(1, 'day');
        while (!isValid) {
          if (newDate.isAfter(maxDate)) {
            break;
          }
          isValid = validFormattedDates.includes(newDate.format('YYYY-MM-DD'));
          if (!isValid) {
            newDate.add(1, 'day');
          }
        };
        break;
      case 'ArrowDown':
        newDate = moment(selectedDate).subtract(1, 'day');
        while (!isValid) {
          if (newDate.isBefore(minDate)) {
            break;
          }
          isValid = validFormattedDates.includes(newDate.format('YYYY-MM-DD'));
          if (!isValid) {
            newDate.subtract(1, 'day');
          }
        };
        break;
      case 'Tab':
      case 'Enter':
        break;
      default:
        event.preventDefault();
        break;
    }
    
    if (newDate && isValid) {
      setDateMessage(newDate.format('DD MMMM'));
      dispatchRequestTime(timeType, newDate, `${selectedHour}:${selectedMinute}`);
    }
  }
  
  const handleHourChange = (value) => {
    dispatchRequestTime(timeType, selectedDate, `${value.target.value}:${selectedMinute}`);
  };
  
  const handleMinuteChange = (value) => {
    dispatchRequestTime(timeType, selectedDate, `${selectedHour}:${value.target.value}`);
  };

  const handleTypeChange = (event, value) => {
    if (value !== null) {
      const format = 'YYYY-MM-DD HH:mm';
      let dateTime = moment(`${selectedDate} ${selectedHour}:${selectedMinute}`, format);
      const timeToAdd = value === 'dropoff' ? minimumMinutesUntilArrival + 5 : 0;
      const currentTime = moment().add(timeToAdd, 'minutes');

      if (dateTime.isBefore(currentTime)) {
        dateTime = currentTime;
      }

      dispatchRequestTime(value, selectedDate, dateTime.format('HH:mm'));
    }
  };

  const handleOnBeOnTime = () => {
    if (type.toLowerCase() === 'fx') {
      dispatch(dialogDisplayMessageAction('Flex, prel avresetid'));
    } else {
      dispatch(dialogDisplayMessageAction('På plats i tid'));
    }
  };

  useEffect(() => {
    const {requestPickupTime, requestDropOffTime} = booking;
    
    let timeType;
    let requestTime;
    const format = 'YYYY-MM-DD HH:mm';
    if (requestPickupTime) {
      timeType = 'pickup';
      requestTime = moment(requestPickupTime, format);
    }

    if (requestDropOffTime) {
      timeType = 'dropoff';
      requestTime = moment(requestDropOffTime, format);
    }

    if (timeType && requestTime) {
      setTimeType(timeType);
      setSelectedDate(requestTime.format('YYYY-MM-DD'));
      setSelectedTimeHours(requestTime.format('HH'));
      setSelectedTimeMinutes(requestTime.format('mm'));
    }
  }, [booking.requestPickupTime, booking.requestDropOffTime]);

  useEffect(() => {
    if (isFetchingFavoriteAddresses) {
      dispatch({ type: constants.ADD_LOADER, payload: 'userFavoriteAddressesList' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'userFavoriteAddressesList' });
    }
    if (isFetchingCustomerAddresses) {
      dispatch({ type: constants.ADD_LOADER, payload: 'userAddressesCustomer' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'userAddressesCustomer' });
    }
  }, [dispatch, isFetchingFavoriteAddresses, isFetchingCustomerAddresses]);

  useEffect(() => {
    setMinimumMinutesUntilArrival(parseInt(settings?.order_minimum_minutes_until_arrival?.value || 60));
  }, [settings]);

  useEffect(() => {
    const format = 'YYYY-MM-DD HH:mm';
    const now = moment();
    const selectedDateTime = moment(`${selectedDate} ${selectedHour}:${selectedMinute}`, format);

    const hasTimePassed = selectedDateTime.isBefore(now);

    const isEnoughTimeUntilArrival = !(timeType === 'dropoff' &&
        selectedDateTime.isSameOrBefore(now.add(minimumMinutesUntilArrival, 'minutes')));

    const isReturn = mode === 'retur';
    const minimumMinutesBetweenTrips = parseInt(settings?.order_minimum_minutes_between_trips.value || 0);
    let lowerBoundDiff = Number.MAX_SAFE_INTEGER;
    let upperBoundDiff = -Number.MAX_SAFE_INTEGER;
    const bookedTrips = orderList?.orders
      .filter((order) => isReturn || order.id !== parseInt(nodeId))
      .filter((order) => {
        const fromDate = moment(order.fromDate, format);
        const lowerBound = moment(fromDate).subtract(minimumMinutesBetweenTrips, 'minutes');
        const upperBound = moment(fromDate).add(minimumMinutesBetweenTrips, 'minutes');
        
        if (selectedDateTime.isBetween(lowerBound, upperBound)) {
          const diff = selectedDateTime.diff(fromDate, 'minutes');
          if (diff < 0) {
            upperBoundDiff = Math.max(upperBoundDiff, diff);
          } else {
            lowerBoundDiff = Math.min(lowerBoundDiff, diff);
          }
          return true;
        }

        return false;
      })
      .filter((order) => {
        const diff = selectedDateTime.diff(moment(order.fromDate, format), 'minutes');
        return diff === lowerBoundDiff || diff === upperBoundDiff;
      })
      .reduce((previous, current) => `${previous}${previous.length > 0 ? ' och en ' : ''}${moment(current.fromDate, format).format('HH:mm')}`, '');

    const messages = [];
    if (hasTimePassed) {
        messages.push(intl.formatMessage(
            { id: "bookTrip.validation.timePassed" },
            { selectedTime: selectedDateTime.format("HH:mm") },
        ));
    }
    if (!isEnoughTimeUntilArrival) {
        messages.push(intl.formatMessage(
            { id: "bookTrip.validation.arrivalTime" },
            { minimumMinutes: minimumMinutesUntilArrival }
        ));
    }
    if (bookedTrips && bookedTrips.length > 0) {
        messages.push(intl.formatMessage(
            { id: "bookTrip.validation.closeTrips" },
            {
              bookedTrips,
              minimumMinutes: minimumMinutesBetweenTrips,
            }
        ));
    }
    setValidationMessages(messages);
  }, [
    selectedDate,
    selectedHour,
    selectedMinute,
    timeType,
    minimumMinutesUntilArrival,
    mode,
    settings,
    orderList,
    nodeId,
    intl,
  ]);

  return isFetchingFavoriteAddresses && isFetchingCustomerAddresses ? (
    <></>
  ) : (
    <Mui.Card className={classes.card}>
      <Mui.CardContent
        aria-controls='trip-content'
        id='trip-header'
        role="region"
        aria-label="Region för vart och när du vill resa"
        tabIndex={0}
      >
        <Mui.Typography className={classes.header} variant='h2'>
          <Icon.DirectionsRoundedIcon color='primary' className={classes.icon} />
          <FormattedMessage id='bookTrip.travel.heading' />
        </Mui.Typography>
        
        <Mui.Grid container>
          <Mui.Grid item xs={10} sm>
            <SelectAddressField
              name='fromAddress'
              label={
                <Mui.Box classes={{ root: classes.label }}>
                  <Icon.RadioButtonUncheckedRoundedIcon color='primary' className={classes.addressFieldIcon} />
                  <FormattedMessage id='bookTrip.travel.from' />
                </Mui.Box>
              }
              aria-label={intl.formatMessage({ id: 'bookTrip.travel.from' })}
              selectedAddress={booking.pickupAddress}
              favoriteAddresses={favoriteAddresses}
              customerAddresses={customerAddresses}
              addressLetter={booking.pickupLetter}
              onChangeLetter={handlePickupLetter}
              onSelectAddress={handlePickupAddress}
            />
          </Mui.Grid>
          <Mui.Grid item className={classes.swapWrapper}>
            <Mui.IconButton
              aria-label='Byt plats på till och från adresserna'
              className={classes.swapButton}
              color='primary'
              size='medium'
              disableFocusRipple
              onClick={handleSwapAddresses}>
              <Icon.SwapHorizIcon fontSize='inherit' color='primary' />
            </Mui.IconButton>
          </Mui.Grid>
          <Mui.Grid item xs={11} sm>
            <SelectAddressField
              name='toAddress'
              label={
                <Mui.Box classes={{ root: classes.label }}>
                  <Icon.RoomIcon color='primary' className={classes.addressFieldIcon} />
                  <FormattedMessage id='bookTrip.travel.to' />
                </Mui.Box>
              }
              aria-label={intl.formatMessage({ id: 'bookTrip.travel.to' })}
              selectedAddress={booking.dropoffAddress}
              favoriteAddresses={favoriteAddresses}
              customerAddresses={customerAddresses}
              addressLetter={booking.dropoffLetter}
              onChangeLetter={handleDropoffLetter}
              onSelectAddress={handleDropoffAddress}
            />
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Mui.Box my={4}>
              <SelectAddressMap
                pickupAddress={booking.pickupAddress}
                dropoffAddress={booking.dropoffAddress}
                displayMap={!isMobile}
              />
            </Mui.Box>
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Mui.ToggleButtonGroup
              exclusive
              size='small'
              className={classes.toggleButtonGroup}
              value={timeType}
              onChange={handleTypeChange}
              aria-label='Tid gäller för'>
              <Mui.ToggleButton value='pickup' aria-label='Avgång' className={classes.toggleButton}>
                Avgång
              </Mui.ToggleButton>
              <Mui.ToggleButton value='dropoff' aria-label='Ankomst' className={classes.toggleButton}>
                Ankomst
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Grid>
          <Mui.Grid item xs={12} style={{display:"flex"}}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale='sv'>
              <KeyboardDatePicker
                required
                autoOk
                disablePast
                disableToolbar
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={(day) => validDates.every((date) => !day.isSame(date))}
                variant="inline"
                format='DD MMMM'
                margin='normal'
                id='select-date'
                label='Datum'
                value={selectedDate}
                onChange={handleDateChange}
                onKeyDown={handleDateKeys}
                onFocus={e => e.target.selectionStart = e.target.selectionEnd}
                role="application"
                inputProps={{"aria-label": `Datumfält kan ändras med upp och ner piltangenterna`}}
                style={{width:"235px"}}
                helperText={dateHelperText}
              />
              <Mui.FormControl margin='normal' aria-label='Ange klockslag i timmar' style={{minWidth:"48px"}}>
                <Mui.InputLabel htmlFor='select-time-h'>Tid</Mui.InputLabel>
                <Mui.NativeSelect
                  id='select-time-h'
                  value={selectedHour}
                  onChange={handleHourChange}
                  inputRef={ref}
                >
                  {Array.from(Array(parseInt(24))).map((value, i) => {
                    const idx = 23 - i;

                    return (
                      <option key={idx} value={idx < 10 ? `0${idx}` : idx}>
                        {idx < 10 ? `0${idx}` : idx}
                      </option>
                    )
                  })}
                </Mui.NativeSelect>
              </Mui.FormControl>
              <Mui.FormControl margin='normal' aria-label='Ange klockslag i minuter' style={{minWidth:"48px"}}>
                <Mui.InputLabel htmlFor='select-time-m'>{` `}</Mui.InputLabel>
                <Mui.NativeSelect
                  id='select-time-m'
                  value={selectedMinute}
                  onChange={handleMinuteChange}
                >
                  {Array.from(Array(parseInt(60))).map((value, i) => {
                    const idx = 59 - i;

                    return (
                      <option key={idx} value={idx < 10 ? `0${idx}` : idx}>
                        {idx < 10 ? `0${idx}` : idx}
                      </option>
                    )
                  })}
                </Mui.NativeSelect>
              </Mui.FormControl>
            </MuiPickersUtilsProvider>
          </Mui.Grid>
          <Mui.Box display='flex' justifyContent='flex-end' marginLeft='auto'>
              <Mui.Button variant='text' color='primary' onClick={handleOnBeOnTime} disableFocusRipple>
                {type.toLowerCase() === 'fx' ? (
                  <FormattedMessage id='bookTripReceipt.beOnTimeFX' />
                ) : (
                  <FormattedMessage id='bookTripReceipt.beOnTime' />
                )}
              </Mui.Button>
            </Mui.Box>
        </Mui.Grid>
        <Mui.Typography variant="srOnly" aria-live="polite" role="status">
          {dateMessage}
        </Mui.Typography>
      </Mui.CardContent>
    </Mui.Card>
  );
});

export default React.memo(TripContainer);
