import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import useDebounce from '../hooks/useDebounce';

import { addressSearch } from '../repository/address';
import { getTypeIcon } from '../utils/helpers';

import Mui from '../components/material';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    fontSize: '0.875rem',
  },
  inputRoot: {
    fontSize: '0.875rem',
    padding: theme.spacing(1, 0, 1, 1),
  },
  addressSection: {
    opacity: 1,
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

const SelectAddressField = ({
  name,
  label,
  selectedAddress,
  favoriteAddresses,
  customerAddresses,
  addressLetter,
  onSelectAddress,
  onChangeLetter,
}) => {
  const classes = useStyles();
  const { type } = useParams(); // nodeId is ether a favoriteTripId or orderId

  const { customer, homeAddress } = useSelector((state) => state.auth);
  const { pickupAddress, dropoffAddress } = useSelector((state) => state.booking);

  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(selectedAddress);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnChange = async (event, value) => {
    const request = {
      query: value.address,
      municipality: [...customer?.allowedMunicipalities],
      flex: type.toLowerCase() === 'fx' ? true : false,
      nearLocation: homeAddress.location,
    };
    const { addresses } = await addressSearch(request);
    const found = addresses.addresser.find((row) => row.address.toLowerCase() === value.address.toLowerCase());

    onSelectAddress(!!found ? { ...found, type: value.type } : addresses.addresser[0]);
    onChangeLetter({ target: { value: !!value.letter ? value.letter : '' } });
  };

  const handleOnInputChange = async (event, value) => {
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
      setIsLoading(false);
      if (type.toLowerCase() === 'fx') {
        setOptions([{ address: 'Mina favoritplatser', section: true }, ...favoriteAddresses]);
      } else {
        setOptions([
          {
            address: `${customer?.strName}${customer?.strNbr ? ` ${customer?.strNbr}` : ''}, ${customer?.postalDistrict}`,
            letter: `${customer?.strNbrLetter}`,
            location: null,
            type: 'home',
          },
          { address: 'Mina favoritplatser', section: true },
          ...favoriteAddresses,
          { address: 'Mina adresser med instruktioner', section: true },
          ...customerAddresses,
        ]);
      }
    }
  };

  const handleOnOpen = async () => {
    if (type.toLowerCase() === 'fx') {
      setOptions([{ address: 'Mina favoritplatser', section: true }, ...favoriteAddresses]);
    } else {
      setOptions([
        {
          address: `${customer?.strName}${customer?.strNbr ? ` ${customer?.strNbr}` : ''}, ${customer?.postalDistrict}`,
          letter: `${customer?.strNbrLetter}`,
          location: null,
          type: 'home',
        },
        { address: 'Mina favoritplatser', section: true },
        ...favoriteAddresses,
        { address: 'Mina adresser med instruktioner', section: true },
        ...customerAddresses,
      ]);
    }

    setOpen(true);
  };

  const doSearchAddress = useCallback(
    async (value) => {
      const request = {
        query: value,
        municipality: [...customer?.allowedMunicipalities],
        flex: type.toLowerCase() === 'fx' ? true : false,
        nearLocation: homeAddress.location,
      };
      const { addresses } = await addressSearch(request);
      setOptions([...addresses.addresser]);
      setIsLoading(false);
    },
    [type, customer]
  );

  const isDisabledOption = (section, address) => section ||
    address.toLowerCase() === pickupAddress?.address.toLowerCase() ||
    address.toLowerCase() === dropoffAddress?.address.toLowerCase();

  useEffect(() => {
    setValue(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoading(true);
      doSearchAddress(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, doSearchAddress]);

  return (
    <Mui.Grid container spacing={2} alignItems='flex-end'>
      <Mui.Grid item xs={type.toLowerCase() !== 'fx' ? 9 : 12}>
        <Mui.Autocomplete
          fullWidth
          disablePortal
          disableClearable
          id={`${name}`}
          forcePopupIcon={false}
          value={value}
          loading={isLoading}
          loadingText={<FormattedMessage id='bookTrip.travel.isLoadingAddressOptions' />}
          options={options}
          filterOptions={(options) => options}
          getOptionLabel={(option) => option.address}
          getOptionDisabled={({section, address}) => isDisabledOption(section, address)}
          noOptionsText={<FormattedMessage id='bookTrip.travel.noAddressOptionsText' />}
          onInputChange={handleOnInputChange}
          onChange={handleOnChange}
          onOpen={handleOnOpen}
          open={open}
          onFocus={handleOnOpen}
          onBlur={() => setOpen(false)}
          classes={{ inputRoot: classes.inputRoot }}
          PopperComponent={(props) => (
            <Mui.Popper {...props} style={{ width: 309, maxWidth: '100%' }} placement='bottom-start' />
          )}
          renderOption={({ address, letter, type, section }) => {
            if (section) {
              return (
                <Mui.Box className={classes.addressSection} aria-label={address}>
                  <Mui.Typography variant='h3' color='primary'>
                    {address}
                  </Mui.Typography>
                </Mui.Box>
              );
            } else {
              const disabled = isDisabledOption(section, address);
              return (
                <Mui.Box display='flex' alignItems='center' aria-label={address.replace(/,/g, `${letter ? letter : ''},`)}>
                  {getTypeIcon(type, disabled)}
                  <Mui.Box color={disabled ? 'text.disabled' : 'text.primary'} ml={3}>
                    {address.replace(/,/g, `${letter ? letter : ''},`)}
                  </Mui.Box>
                </Mui.Box>
              );
            }
          }}
          renderInput={(params) => (
            <Mui.TextField
              {...params}
              required
              variant='standard'
              helperText='Obligatoriskt'
              label={label}
              classes={{ root: classes.textField }}
            />
          )}
        />
      </Mui.Grid>
      {type.toLowerCase() !== 'fx' && (
        <Mui.Grid item xs={3}>
          <Mui.TextField
            fullWidth
            label='Uppg.'
            title='Uppgång'
            autoComplete='on'
            helperText=' '
            id={`${name}-letter`}
            InputLabelProps={{ 'aria-label': 'Uppgång' }}
            aria-labelledby={`${name}-label ${`${name}-letter`}-label`}
            value={addressLetter}
            onChange={onChangeLetter}
            inputProps={{ maxLength: 1 }}
            classes={{ root: classes.textField }}
            onInput={(e) => {
              if (!/^[A-Za-z]+$/.test(e.target.value)) {
                e.target.value = '';
              }
            }}
          />
        </Mui.Grid>
      )}
      <Typography variant="srOnly" aria-live="polite" aria-atomic="true" role="status">Det finns {options.length} förslag i listan relaterade till din sökning på {searchTerm}</Typography>
    </Mui.Grid>
  );
};

export default React.memo(SelectAddressField);
