import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectIconsHelper } from '../utils/helpers';
import { testValidFrom, testValidUntil } from '../utils/validators';
import { themes } from '../themes/themes';

import constants from '../store/constants';
import { dialogDisplayMessageAction } from '../store/dialog/dialogActions';
import usePermits from '../hooks/usePermits';

import Mui from '../components/material';

import FavoriteTripsList from '../components/FavoriteTripsList';
import NewsList from '../components/NewsList';
import Banner from '../components/Banner';

import NoPermitsStart from './NoPermitsStart';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  content: {
    textAlign: 'center',
  },
}));

const Start = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: permits, isFetching } = usePermits();
  const [permit, setPermit] = useState({});

  const handleChange = (event) => {
    const permit = event.target.value;
    setPermit(permit);
    if (parseInt(permit.tripCounterConsumed) >= permit.tripsGranted) {
      dispatch(dialogDisplayMessageAction('Max antal resor'));
    } else {
      history.push(`/boka-resa/${permit.travelType.type}/${permit.travelType.travelTypeId}`);
    }
  };

  useEffect(() => {
    if (!permits && isFetching) {
      dispatch({ type: constants.ADD_LOADER, payload: 'userPermitsList' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'userPermitsList' });
    }
  }, [dispatch, permits, isFetching]);

  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  return (
    <>
      <Helmet>
        <title>
          {`Vad vill du boka? | ${intl.formatMessage({ id: `app.title.${region}` })} ${
            themes[region].name
          }`}
        </title>
      </Helmet>
      {!permits && isFetching ? (
        <Banner />
      ) : permits && permits.length ? (
        <>
          <Banner />
          <Mui.Container maxWidth='xs'>
            <Mui.Box pt={6} pb={3}>
              <Mui.Typography variant='h2' className={classes.headline} tabIndex={0}>
                <FormattedMessage id='start.heading' />
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box mb={6}>
              <Mui.FormControl variant='outlined' fullWidth className={classes.formControl}>
                <Mui.InputLabel id='permits-label'>{<FormattedMessage id='start.selectTripType' />}</Mui.InputLabel>
                <Mui.Select
                  fullWidth
                  labelId='permits-label'
                  id='permits'
                  value={permit}
                  onChange={handleChange}
                  label={<FormattedMessage id='start.selectTripType' />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}>
                  {permits
                    .filter((v) => v.travelType.bookable &&
                      testValidFrom(v.validFrom) &&
                      testValidUntil(v.validUntil)
                    )
                    .map((permit) => (
                      <Mui.MenuItem value={permit} key={permit.travelTypeID}>
                        <Mui.Box mr={2} className={classes.icon} aria-hidden='true'>
                          {selectIconsHelper(permit.travelType)}
                        </Mui.Box>
                        {permit.travelType.name}
                      </Mui.MenuItem>
                    ))}
                </Mui.Select>
              </Mui.FormControl>
            </Mui.Box>
            <Mui.Box mb={6}>
              <FavoriteTripsList />
            </Mui.Box>
            <Mui.Box>
              <NewsList />
            </Mui.Box>
          </Mui.Container>
        </>
      ) : (
        <NoPermitsStart />
      )}
    </>
  );
};

export default React.memo(Start);
