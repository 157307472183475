import React from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';
import moment from 'moment';

import { tripsCost } from '../../repository/trips';
import { selectIconsHelper } from '../../utils/helpers';
import usePermits from '../../hooks/usePermits';

import Mui from '../../components/material';
import Icon from '../../components/icons';

import NoPermitImage from '../../images/permits.png';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  infoText: {
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  image: {
    width: '100%',
  },
  noPermitsText: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0),
  },
  listItemText: {
    fontWeight: 500,
  },
  accordionHeader: {
    fontSize: '1rem',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const MyPermits = () => {
  const intl = useIntl();
  const classes = useStyles();
  const enviroments = ['hl', 'smf'];

  const { data: permits } = usePermits();
  const { data: monthly } = useQuery('tripsCost', tripsCost, { retry: false, refetchOnWindowFocus: false });

  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  return (
    <>
      <Helmet>
        <title>
          {`Mina tillstånd | ${intl.formatMessage({ id: `app.title.${region}` })} ${
            themes[region].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline} tabIndex={0}>
            <FormattedMessage id='myPage.myPermits.heading' />
          </Mui.Typography>
          <Mui.Typography variant='body1' className={classes.infoText} tabIndex={0}>
            <FormattedMessage id='myPage.myPermits.infoText' />
            {enviroments.includes(region) && <FormattedMessage id='myPage.myPermits.monthlyText' />}
          </Mui.Typography>
        </Mui.Box>
        {permits ? (
          <Mui.Box mb={6}>
            {permits.map((permit) => (
              <Mui.Accordion key={permit.travelTypeID}>
                <Mui.AccordionSummary
                  expandIcon={<Icon.ExpandMoreIcon color='primary' />}
                  aria-controls={`${permit.travelTypeID}-content`}
                  id={`${permit.travelTypeID}-header`}>
                  <Mui.Box display='flex' alignItems='center'>
                    <Mui.Box mr={2} aria-hidden='true'>
                      {selectIconsHelper(permit.travelType)}
                    </Mui.Box>
                    <Mui.Box>
                      <Mui.Typography className={classes.accordionHeader} variant='h2'>
                        {permit.travelType.name}
                      </Mui.Typography>
                      <FormattedMessage id='myPage.myPermits.validUntil' /> {permit.validUntil}
                    </Mui.Box>
                  </Mui.Box>
                </Mui.AccordionSummary>
                <Mui.AccordionDetails>
                  <Mui.List disablePadding>
                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.validFrom' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.validFrom}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.tripsDone' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.tripCounterConsumed}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.tripsLeft' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.tripsGranted - parseInt(permit.tripCounterConsumed)}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.tripsGranted' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.tripsGranted}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.onlyTraveller' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.sharedRide.includes('J') ? (
                          <FormattedMessage id='myPage.myPermits.no' />
                        ) : (
                          <FormattedMessage id='myPage.myPermits.yes' />
                        )}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.companions' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.maxCompanions}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.Divider variant='fullWidth' />

                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.extraAids' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {permit.extraAid.map((ea) => ea.description).join(', ')}
                      </Mui.Typography>
                    </Mui.ListItem>
                  </Mui.List>
                </Mui.AccordionDetails>
              </Mui.Accordion>
            ))}
            {monthly && (
              <Mui.Accordion>
                <Mui.AccordionSummary
                  expandIcon={<Icon.ExpandMoreIcon color='primary' />}
                  aria-controls={`tripsCost-content`}
                  id={`tripsCost-header`}>
                  <Mui.Box display='flex' alignItems='center'>
                    <Mui.Box mr={2}>
                      <Icon.AccountBalanceWalletRoundedIcon color='primary' />
                    </Mui.Box>
                    <Mui.Box>
                      <Mui.Typography className={classes.accordionHeader} variant='h2' tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.monthlyCost' />
                      </Mui.Typography>
                      <span className={classes.capitalize} tabIndex={0}>{moment().format('MMMM')}</span>
                    </Mui.Box>
                  </Mui.Box>
                </Mui.AccordionSummary>
                <Mui.AccordionDetails>
                  <Mui.List disablePadding>
                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.tripsDone' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {monthly.nrOfTrips}
                      </Mui.Typography>
                    </Mui.ListItem>
                    <Mui.ListItem disableGutters className={classes.listItem}>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        <FormattedMessage id='myPage.myPermits.amount' />
                      </Mui.Typography>
                      <Mui.Typography variant='body1' className={classes.listItemText} tabIndex={0}>
                        {monthly.monthlySum} SEK
                      </Mui.Typography>
                    </Mui.ListItem>
                  </Mui.List>
                </Mui.AccordionDetails>
              </Mui.Accordion>
            )}
          </Mui.Box>
        ) : (
          <Mui.Box mb={6}>
            <img src={NoPermitImage} alt='' className={classes.image} />
            <Mui.Typography variant='h2' color='primary' className={classes.noPermitsText} tabIndex={0}>
              <FormattedMessage id='myPage.myPermits.noPermits' />
            </Mui.Typography>
          </Mui.Box>
        )}
      </Mui.Container>
    </>
  );
};

export default React.memo(MyPermits);
