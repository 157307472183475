import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Mui from './material';

const useStyles = makeStyles((theme) => ({
  banner: {
    background: theme.palette.primary.main,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
}));

const Banner = () => {
  const classes = useStyles();

  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  return (
    <div className={classes.banner}>
      <Mui.Typography variant='h1' className={classes.title}>
        <FormattedMessage id={`app.title.${region}`} />
      </Mui.Typography>
    </div>
  );
};

export default Banner;
