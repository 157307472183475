import CONSTANTS from '../../store/constants';

export const dialogDisplayMessageAction = (code) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'displayMessage', content: code } };
};

export const dialogDisplayInformationAction = (content, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'displayInformation', content: content, callback: callback } };
};

export const dialogChangePasswordAction = () => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'changePassword', content: null } };
};

export const dialogDeleteUserAction = () => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteUser', content: null } };
};

export const dialogDeleteTripAction = (order) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteTrip', content: order } };
};

export const dialogEditTripAction = (order) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'editTrip', content: order } };
};

export const dialogDeleteMultipleTripAction = (orders, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteMultipleTrip', callback: callback, content: orders } };
};

export const dialogCreateFavoriteTripAction = (order) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'createFavoriteTrip', content: order } };
};

export const dialogUpdateFavoriteTripAction = (trip, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'updateFavoriteTrip', callback: callback, content: trip } };
};

export const dialogDeleteFavoriteTripAction = (trip, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteFavoriteTrip', callback: callback, content: trip } };
};

export const dialogDeleteAgentAction = (name, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteAgent', callback: callback, content: name } };
};

export const dialogDeleteTravellerAction = (username, callback) => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'deleteTraveller', callback: callback, content: username } };
};

export const dialogInactiveUserAction = () => {
  return { type: CONSTANTS.OPEN_DIALOG, payload: { dialog: 'inactiveUser', content: null } };
};

export const dialogCloseAction = (dialog) => {
  return { type: CONSTANTS.CLOSE_DIALOG, payload: { dialog: dialog } };
};

export const dialogCloseAllAction = () => {
  return { type: CONSTANTS.CLOSE_ALL_DIALOG };
};
