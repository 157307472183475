import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';

import Mui from './material';
import Icon from './icons';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'relative',
    maxWidth: '100%',
    height: 200,
  },
  marker: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    userSelect: 'none',
    transform: 'translate(-50%, -50%)',
  },
}));

const Marker = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.marker}>{children}</div>;
};

const SelectAddressMap = ({ pickupAddress, dropoffAddress, displayMap, vehicle = null }) => {
  const classes = useStyles();

  const [showMap, setShowMap] = useState(displayMap);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [map, setMap] = useState(null);

  const handleApiLoaded = (map, gmaps) => {
    if (pickupAddress || dropoffAddress) {
      const bounds = new gmaps.LatLngBounds();
      if (pickupAddress) bounds.extend(new gmaps.LatLng(pickupAddress.location.lat, pickupAddress.location.lon));
      if (dropoffAddress) bounds.extend(new gmaps.LatLng(dropoffAddress.location.lat, dropoffAddress.location.lon));
      map.fitBounds(bounds);
    }

    setMap(map);
    setGoogleMaps(gmaps);
  };

  useEffect(() => {
    if (googleMaps && map && (pickupAddress || dropoffAddress)) {
      if (!vehicle) {
        const bounds = new googleMaps.LatLngBounds();
        if (pickupAddress) bounds.extend(new googleMaps.LatLng(pickupAddress.location.lat, pickupAddress.location.lon));
        if (dropoffAddress) bounds.extend(new googleMaps.LatLng(dropoffAddress.location.lat, dropoffAddress.location.lon));
        map.fitBounds(bounds);
      }
    }
  }, [map, googleMaps, vehicle, pickupAddress, dropoffAddress]);

  const {
    MAP_KEY: mapKey,
    MAP_CENTER_LAT: mapCenterLat,
    MAP_CENTER_LNG: mapCenterLng
  } = JSON.parse(localStorage.getItem('env')) || {
    MAP_KEY: '',
    MAP_CENTER_LAT: 0,
    MAP_CENTER_LNG: 0
  };

  return (
    <>
      {showMap && (
        <div className={classes.map} role='application' aria-label='Karta med till och från adress'>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: mapKey }}
            defaultCenter={{
              lat: parseFloat(mapCenterLat),
              lng: parseFloat(mapCenterLng),
            }}
            defaultZoom={10}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
            {vehicle && (
              <Marker lat={vehicle.lat} lng={vehicle.lon}>
                <Icon.DriveEtaRoundedIcon color='primary' />
              </Marker>
            )}
            {pickupAddress && (
              <Marker lat={pickupAddress.location.lat} lng={pickupAddress.location.lon}>
                <Icon.RadioButtonUncheckedRoundedIcon color='primary' />
              </Marker>
            )}
            {dropoffAddress && (
              <Marker lat={dropoffAddress.location.lat} lng={dropoffAddress.location.lon}>
                <Icon.RoomIcon color='primary' />
              </Marker>
            )}
          </GoogleMapReact>
        </div>
      )}

      {isMobile && !showMap && (
        <Mui.Box mt={8} mb={5}>
          <Mui.Button
            variant='outlined'
            color='primary'
            disableFocusRipple
            onClick={() => setShowMap(true)}
            startIcon={<Icon.MapRoundedIcon color='primary' />}
            style={{ width: '100%' }}>
            <FormattedMessage id='bookTrip.travel.seeOnMap' />
          </Mui.Button>
        </Mui.Box>
      )}
    </>
  );
};

export default React.memo(SelectAddressMap);
