import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';

import { userUpdateAction } from '../../store/auth/authActions';
import { dialogChangePasswordAction, dialogDeleteUserAction } from '../../store/dialog/dialogActions';
import { userUpdate } from '../../repository/user';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { validatePhoneNbr } from '../../utils/validators';
import { getDisplayName } from '../../utils/helpers';

import Mui from '../../components/material';
import Icon from '../../components/icons';
import UserInitials from '../../components/UserInitials';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  fullWidth: {
    flex: '1',
  },
  icon: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  delete: {
    width: '100%',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:focus, &:active': {
      backgroundColor: theme.palette.error.dark,
      outline: `3px solid ${theme.palette.error.light}`,
      outlineOffset: -3,
    },
  },
}));

const MyProfile = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  const { user, customer } = useSelector((state) => state.auth);
  const [disabled] = useState(region === 'smf' ? true : false);

  const [email, setEmail] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [phoneNbrError, setPhoneNbrError] = useState(false);
  const [phoneNbrHelpText, setPhoneNbrHelpText] = useState('');

  const handleOnUpdate = async () => {
    if (!customer) return;
    try {
      const request = {
        customerVersion: customer.customerVersion,
        email: email,
        phoneNumber: phoneNbr,
      };
      const { success } = await userUpdate(request);

      if (success) {
        dispatch(snackbarOpenAction('Din profil är uppdaterad'));
        dispatch(userUpdateAction());
      } else {
        dispatch(snackbarOpenAction('Din profil kunde inte uppdateras'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    dispatch(dialogChangePasswordAction());
  };

  const handleClickDelete = () => {
    dispatch(dialogDeleteUserAction());
  };

  const handleUpdatePhonenbr = (event) => {
    setPhoneNbr(event.target.value);
  };

  const handleUpdateEmail = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    setEmail(customer?.email ? customer.email : '');
    setPhoneNbr(customer?.phoneNbr ? customer.phoneNbr : '');
  }, [customer?.email, customer?.phoneNbr]);

  return (
    <>
      <Helmet>
        <title>
          {`Min profil | ${intl.formatMessage({ id: `app.title.${region}` })} ${
            themes[region].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline} tabIndex={0}>
            <FormattedMessage id='myPage.myProfile.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          <Mui.Grid container spacing={4}>
            <Mui.Grid item xs={12} sm={5} className={classes.center}>
              <UserInitials user={user} size='large' />
              <Mui.Typography variant='h2' tabIndex={0}>
                {getDisplayName(user)}
              </Mui.Typography>
              <Mui.Box mt={4}>
                <Mui.Button variant='outlined' color='primary' fullWidth onClick={handleClickOpen} disableFocusRipple>
                  <FormattedMessage id='myPage.myProfile.btnChangePassword' />
                </Mui.Button>
              </Mui.Box>
              <Mui.Box mt={4}>
                <Mui.Button
                  className={classes.delete}
                  variant="contained"
                  color="default"
                  fullWidth
                  disableFocusRipple
                  onClick={handleClickDelete}
                >
                  <FormattedMessage id="myPage.myProfile.btnRemoveAccount" />
                </Mui.Button>
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid item sm={7}>
              <Mui.TextField
                fullWidth
                value={phoneNbr}
                disabled={disabled || !customer}
                aria-disabled={disabled || !customer}
                id='phone'
                autoComplete='chrome-off'
                label='Telefonnummer'
                margin='normal'
                error={phoneNbrError}
                helperText={phoneNbrHelpText}
                onBlur={(e) => {
                  if (e.target.value) {
                    const { error, helptext } = validatePhoneNbr(e.target.value, '');
                    setPhoneNbrError(error);
                    setPhoneNbrHelpText(helptext);
                  }
                }}
                onChange={handleUpdatePhonenbr}
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position='start'>
                      <Icon.PhoneRoundedIcon color='primary' />
                    </Mui.InputAdornment>
                  ),
                }}
              />
              <Mui.TextField
                fullWidth
                value={email}
                disabled={disabled || !customer}
                aria-disabled={disabled || !customer}
                id='email'
                autoComplete='email'
                label='E-post'
                margin='normal'
                onChange={handleUpdateEmail}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment position='start'>
                      <Icon.EmailRoundedIcon color='primary' />
                    </Mui.InputAdornment>
                  ),
                }}
              />
              <Mui.FormControl fullWidth margin='normal'>
                <Mui.Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={phoneNbrError || disabled || !customer}
                  onClick={handleOnUpdate}
                  disableFocusRipple>
                  Spara
                </Mui.Button>
              </Mui.FormControl>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(MyProfile);
