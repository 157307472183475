import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';

import Mui from '../../components/material';
import AddTravellersContainer from '../../containers/mypage/AddTravellersContainer';
import ManageTravellersContainer from '../../containers/mypage/ManageTravellersContainer';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  infoText: {
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
}));

const MyTravellers = () => {
  const intl = useIntl();
  const classes = useStyles();

  const region = JSON.parse(localStorage.getItem('env')).REGION || '';

  return (
    <>
      <Helmet>
        <title>
          {`Mina resenärer | ${intl.formatMessage({ id: `app.title.${region}` })} ${
            themes[region].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline} tabIndex={0}>
            <FormattedMessage id='myPage.myTravellers.heading' />
          </Mui.Typography>
          <Mui.Typography variant='body1' className={classes.infoText} tabIndex={0}>
            <FormattedMessage id='myPage.myTravellers.infoText' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          <AddTravellersContainer />
        </Mui.Box>
        <Mui.Box mb={6}>
          <ManageTravellersContainer />
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(MyTravellers);
